<template>
  <div class='about'>
    <section>
        <b-message>
            <p>Platinum-based chemotherapy, including cisplatin, carboplatin and oxaliplatin, is prescribed to 10-20% of all 
            cancer patients. Unfortunately, platinum resistance develops in a significant number of patients and is a 
            determinant of clinical outcome. 
            </p>
            
            <p>
            Extensive research has been conducted to understand and overcome platinum 
            resistance, and mechanisms of resistance can be categorized into several broad biological processes, including:<br> 
            1) regulation of drug entry, exit, accumulation, sequestration and detoxification,<br>
            2) enhanced repair and tolerance of platinum-induced DNA damage,<br>
            3) alterations in cell survival pathways,<br>
            4) alterations in pleiotropic processes and pathways,<br>
            5) changes in the tumor microenvironment.  
            </p>

            <p>
            As a resource to the cancer research community, we provide a comprehensive overview 
            accompanied by a manually curated database of the >900 genes/proteins 
            that have been associated with platinum resistance over the last 30 years of literature.  
            </p>

            <p>
            The database is highly annotated, including possible pathways 
            through which the curated genes are related to platinum resistance, types of evidence, 
            and hyperlinks to literature sources. 
            </p>
        </b-message>
    </section>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.about {
    margin: 15px 30px;
}

.about p {
    margin: 10px;
}
</style>