export default {"Apoptotic signaling": {'AATF': 1.0, 'ABHD2': 4.0, 'ABL1': 2.0, 'ACVR1C': 4.0, 'APEX1': 1.0, 'ARHGDIB': 2.0, 'ATF2': 1.0, 'ATF3': 1.0, 'AXL': 4.0, 'BAP1': 4.0, 'BIN1': 1.0, 'C1QBP': 4.0, 'CAPN1': 1.0, 'CASP12': 2.0, 'CASP4': 1.0, 'CAVIN3': 4.0, 'CCNI': 4.0, 'CD40': 4.0, 'CDK5': 1.0, 'CEBPB': 1.0, 'CLPTM1L': 4.0, 'CLU': 5.0, 'CREB1': 1.0, 'CRYAB': 4.0, 'DDB1': 1.0, 'DDB2': 5.0, 'DDIT3': 5.0, 'DICER1': 4.0, 'DRAM1': 1.0, 'DYRK2': 5.0, 'EGR1': 5.0, 'EIF3G': 1.0, 'EIF4EBP1': 2.0, 'EIF4G2': 4.0, 'EP300': 4.0, 'FAU': 4.0, 'FOXO1': 4.0, 'FOXO3': 5.0, 'GJA1': 4.0, 'GSN': 4.0, 'HDAC3': 4.0, 'HECTD3': 5.0, 'HIC1': 3.0, 'HIPK2': 1.0, 'HSPA5': 5.0, 'HTRA2': 4.0, 'IRF1': 4.0, 'ITPR1': 1.0, 'JUN': 5.0, 'JUNB': 2.0, 'JUND': 1.0, 'KAT5': 1.0, 'KAT8': 1.0, 'KCNMA1': 4.0, 'KLF5': 4.0, 'MELK': 4.0, 'MICU1': 5.0, 'MIEN1': 1.0, 'MITF': 1.0, 'MMP7': 4.0, 'MOAP1': 1.0, 'MSX1': 4.0, 'MUC16': 1.0, 'MUTYH': 1.0, 'NDUFA13': 4.0, 'NEK11': 1.0, 'NOS2': 1.0, 'NR4A1': 5.0, 'NTRK3': 3.0, 'ORAI1': 1.0, 'PAK5': 4.0, 'PAX2': 2.0, 'PDCD4': 2.0, 'PEA15': 2.0, 'PIM1': 1.0, 'PIM2': 1.0, 'PKM': 4.0, 'PLAA': 4.0, 'PLK2': 4.0, 'PMAIP1': 1.0, 'PML': 2.0, 'RASSF1': 4.0, 'RELA': 5.0, 'RELB': 4.0, 'RIPK3': 4.0, 'SERPINB3': 4.0, 'SHC1': 1.0, 'SIX1': 4.0, 'SPINK7': 1.0, 'SRSF2': 4.0, 'STIM1': 4.0, 'STK11': 2.0, 'STK17A': 1.0, 'THBS1': 4.0, 'TNFRSF12A': 4.0, 'TP53': 5.0, 'TP53INP1': 1.0, 'TP63': 4.0, 'TP73': 4.0, 'TPT1': 4.0, 'TRIM27': 5.0, 'TXNL1': 1.0, 'UBR5': 4.0, 'URI1': 5.0, 'ZBTB7A': 1.0, 'ZNF143': 1.0}, 
"DDR": {'AATF': 1.0, 'ARL6IP5': 4.0, 'ATF2': 1.0, 'ATM': 3.0, 'ATR': 1.0, 'ATRIP': 1.0, 'BAP1': 4.0, 'BIN1': 1.0, 'BRCA1': 5.0, 'CDC7': 1.0, 'CHEK1': 5.0, 'CHEK2': 4.0, 'DDIAS': 1.0, 'DUSP1': 1.0, 'FAM168A': 4.0, 'FLNA': 2.0, 'FLT4': 5.0, 'KAT5': 1.0, 'KAT8': 1.0, 'KLF5': 4.0, 'LCK': 1.0, 'MAPK1': 1.0, 'MAPK3': 1.0, 'NEK11': 1.0, 'PAQR3': 1.0, 'PCLAF': 4.0, 'PCNA': 5.0, 'PGK1': 4.0, 'PLK1': 5.0, 'POLB': 4.0, 'POLD1': 4.0, 'POLD2': 4.0, 'POLD3': 4.0, 'POLE3': 2.0, 'POLM': 1.0, 'POLN': 1.0, 'POLQ': 1.0, 'PPM1D': 2.0, 'PPP2R2A': 1.0, 'RASSF1': 4.0, 'RFC1': 1.0, 'RFC2': 1.0, 'RHOJ': 1.0, 'RPA1': 1.0, 'RPA2': 1.0, 'SFN': 1.0, 'SIRT1': 5.0, 'SLFN11': 4.0, 'SMARCA2': 4.0, 'SMARCA4': 4.0, 'TET2': 1.0, 'TIMELESS': 2.0, 'TLK1': 1.0, 'TOP1': 3.0, 'TOP2A': 3.0, 'TOP3A': 4.0, 'TOP3B': 3.0, 'TOPBP1': 4.0, 'TP63': 4.0, 'TRIB2': 4.0, 'UNG': 1.0, 'USP22': 2.0, 'WDR48': 1.0, 'YY1': 5.0, 'ZNF143': 1.0}, 
"p53 signaling": {'AATF': 1.0, 'ANKRD1': 4.0, 'ANXA2': 5.0, 'AURKA': 5.0, 'BBC3': 2.0, 'BCL2L1': 5.0, 'CASP6': 2.0, 'CASP7': 1.0, 'CCDC69': 4.0, 'CDKN1A': 4.0, 'CDKN2A': 1.0, 'DAPK1': 1.0, 'DAXX': 5.0, 'DRAM1': 1.0, 'DYRK2': 5.0, 'EP300': 4.0, 'HIC1': 3.0, 'HIPK2': 1.0, 'HSPA5': 5.0, 'KDM3A': 2.0, 'KDM5A': 1.0, 'LRP1': 1.0, 'MDM2': 4.0, 'MDM4': 1.0, 'MNAT1': 1.0, 'MSX1': 4.0, 'MUTYH': 1.0, 'NAPSA': 1.0, 'NOS2': 1.0, 'NPM1': 3.0, 'PARK7': 4.0, 'PHB': 4.0, 'PLK2': 4.0, 'PMAIP1': 1.0, 'PPM1D': 2.0, 'PRKAA2': 1.0, 'RPL37': 1.0, 'RPS20': 4.0, 'S100B': 1.0, 'SFN': 1.0, 'SHC1': 1.0, 'SIRT1': 5.0, 'SPINK7': 1.0, 'STK11': 2.0, 'TAB1': 1.0, 'TNFRSF12A': 4.0, 'TP53': 5.0, 'TP53INP1': 1.0, 'TPT1': 4.0, 'VHL': 1.0, 'XPO1': 5.0}, 
"Platinum efflux": {'ABCA8': 1.0, 'ABCB1': 4.0, 'ABCB5': 4.0, 'ABCC1': 4.0, 'ABCC2': 5.0, 'ABCC3': 4.0, 'ABCC4': 1.0, 'ABCC5': 2.0, 'ABCD2': 4.0, 'ABCF2': 1.0, 'ABCG2': 4.0, 'ANXA4': 4.0, 'APC': 4.0, 'ARID1A': 4.0, 'ATP11B': 5.0, 'ATP7A': 4.0, 'ATP7B': 4.0, 'CHMP2B': 1.0, 'CLN3': 1.0, 'EHD1': 4.0, 'EIF2AK3': 2.0, 'GNPTG': 1.0, 'HOXB4': 1.0, 'IRF1': 4.0, 'KAT5': 1.0, 'LDLR': 4.0, 'LRRFIP1': 1.0, 'MAPK9': 1.0, 'MGAT1': 1.0, 'MGAT5': 1.0, 'MVP': 4.0, 'NFE2L2': 4.0, 'NOS1': 1.0, 'NOS3': 1.0, 'NR1I2': 1.0, 'PDZK1': 1.0, 'PER2': 2.0, 'PGRMC1': 2.0, 'PTCH1': 1.0, 'PTGER3': 2.0, 'PTGS2': 3.0, 'RAB7A': 4.0, 'RAB8A': 1.0, 'SLC27A2': 4.0, 'SLC7A11': 4.0, 'SMARCE1': 1.0, 'TMEM205': 1.0, 'TNFAIP8L2': 4.0, 'UGCG': 2.0, 'YY1': 5.0}, 
"PKA signaling": {'ABCC4': 1.0, 'ATF2': 1.0, 'ATF3': 1.0, 'ATF4': 2.0, 'ATF6': 4.0, 'ATP1B1': 1.0, 'BAD': 4.0, 'CAMK2D': 4.0, 'CREB1': 1.0, 'EP300': 4.0, 'GLI1': 4.0, 'NF1': 2.0, 'ORAI1': 1.0, 'PRKAR1A': 4.0, 'PRKG1': 4.0, 'PTAFR': 2.0, 'PTCH1': 1.0, 'SIK2': 1.0}, 
"CSC": {'ABCG2': 4.0, 'ALDH1A1': 5.0, 'ALDH3A1': 2.0, 'APC': 4.0, 'AREG': 5.0, 'ARID3B': 4.0, 'BEX3': 2.0, 'BMI1': 2.0, 'CD24': 5.0, 'CD44': 5.0, 'CD55': 2.0, 'CDH1': 4.0, 'CDH2': 4.0, 'COL3A1': 4.0, 'DDB1': 1.0, 'DDB2': 5.0, 'DDR1': 4.0, 'DNMT1': 2.0, 'DYRK2': 5.0, 'EDNRA': 5.0, 'EPCAM': 5.0, 'ETS1': 4.0, 'EZR': 4.0, 'FN1': 4.0, 'FOXP1': 5.0, 'GABPA': 1.0, 'GLI2': 4.0, 'GREM1': 2.0, 'HMOX1': 4.0, 'HOXA4': 4.0, 'HOXD8': 4.0, 'IGF2BP1': 1.0, 'ITGA6': 4.0, 'KDM3A': 2.0, 'KIT': 5.0, 'KLF4': 4.0, 'KLF5': 4.0, 'KRT18': 4.0, 'KRT8': 2.0, 'LCK': 1.0, 'LGALS3': 1.0, 'LIN28A': 4.0, 'LIN28B': 4.0, 'MAL': 4.0, 'METTL6': 4.0, 'MME': 1.0, 'MMP10': 4.0, 'MMP9': 4.0, 'MYD88': 4.0, 'MZF1': 1.0, 'NANOG': 4.0, 'NES': 4.0, 'NOTCH1': 2.0, 'PBX1': 4.0, 'PBXIP1': 4.0, 'POU5F1': 4.0, 'PRKG1': 4.0, 'PROM1': 5.0, 'S100B': 1.0, 'SERPINE1': 1.0, 'SFRP5': 5.0, 'SGK1': 2.0, 'SNAI1': 1.0, 'SNAI2': 1.0, 'SOX2': 4.0, 'SOX9': 4.0, 'SUSD2': 5.0, 'TEAD1': 1.0, 'TEAD3': 1.0, 'TEAD4': 1.0, 'TERT': 4.0, 'TET1': 4.0, 'TGFB1': 5.0, 'TGFB2': 1.0, 'TGFB3': 4.0, 'TGFBR1': 1.0, 'TGFBR2': 1.0, 'TNF': 1.0, 'TPT1': 4.0, 'TWIST1': 5.0, 'VIM': 1.0, 'WWOX': 2.0, 'ZEB1': 2.0}, 
"Hedgehog signaling": {'ABCG2': 4.0, 'GLI1': 4.0, 'GLI2': 4.0, 'KIF20A': 4.0, 'PTCH1': 1.0, 'SMO': 5.0, 'USP37': 2.0}, 
"Calcium signaling": {'ABHD2': 4.0, 'ANXA3': 1.0, 'CAMK2D': 4.0, 'ITPR1': 1.0, 'MICU1': 5.0, 'NOX5': 2.0, 'ORAI1': 1.0, 'STIM1': 4.0, 'TMEM97': 3.0}, 
"Oncogene signaling": {'ABL1': 2.0, 'ADAM17': 4.0, 'ADAM9': 4.0, 'AKT1': 5.0, 'AKT1S1': 1.0, 'AKT2': 2.0, 'AMFR': 3.0, 'ANXA3': 1.0, 'AXL': 4.0, 'BSG': 4.0, 'CCL25': 4.0, 'CCR9': 4.0, 'CDCP1': 5.0, 'CIP2A': 5.0, 'CLPTM1L': 4.0, 'CLU': 5.0, 'EGR1': 5.0, 'EIF4E': 2.0, 'EIF4EBP1': 2.0, 'ENPP2': 4.0, 'EPCAM': 5.0, 'EPHA2': 1.0, 'FOXC2': 5.0, 'FOXO1': 4.0, 'FSTL1': 1.0, 'GAS6': 4.0, 'GDF15': 4.0, 'GFRA1': 4.0, 'HOXB13': 4.0, 'HSPA5': 5.0, 'IGF2BP1': 1.0, 'IGF2BP3': 4.0, 'KAT8': 1.0, 'LIN28A': 4.0, 'LIN28B': 4.0, 'MNAT1': 1.0, 'MTOR': 2.0, 'MUC1': 2.0, 'MVP': 4.0, 'NAV3': 4.0, 'NCOA3': 3.0, 'NEDD4': 1.0, 'NEDD8': 5.0, 'NES': 4.0, 'NF1': 2.0, 'NF2': 3.0, 'NKX2-8': 4.0, 'NTRK3': 3.0, 'PDCD4': 2.0, 'PDGFD': 3.0, 'PTGER3': 2.0, 'PTGS2': 3.0, 'RET': 2.0, 'RPS7': 1.0, 'SERPINB2': 4.0, 'SFN': 1.0, 'SMARCA4': 4.0, 'SOX10': 4.0, 'ST6GAL1': 1.0, 'STAT5A': 4.0, 'STAT5B': 4.0, 'STIM1': 4.0, 'TP63': 4.0, 'TRIB2': 4.0, 'TRO': 4.0, 'UCHL1': 1.0, 'WFDC2': 4.0}, 
"DDR/HR": {'ABRAXAS2': 4.0, 'ATMIN': 4.0, 'BARD1': 1.0, 'BLM': 4.0, 'BRCA1': 5.0, 'BRCA2': 5.0, 'BRD9': 1.0, 'BRIP1': 5.0, 'C1QBP': 4.0, 'CCNA2': 5.0, 'CDK12': 1.0, 'CDK2': 4.0, 'DCLRE1A': 1.0, 'DCLRE1B': 1.0, 'DYNLL1': 4.0, 'E2F1': 4.0, 'E2F7': 4.0, 'EME1': 4.0, 'EMSY': 4.0, 'ERCC4': 4.0, 'EXO1': 4.0, 'FH': 5.0, 'FOXM1': 4.0, 'GPBP1': 4.0, 'H2AX': 4.0, 'HELQ': 4.0, 'HROB': 1.0, 'KMT2C': 1.0, 'LIG3': 1.0, 'MCM8': 1.0, 'MCM9': 2.0, 'MRE11': 4.0, 'MSH3': 1.0, 'MSH5': 1.0, 'MUS81': 2.0, 'NBN': 1.0, 'PALB2': 4.0, 'PARG': 1.0, 'PARP1': 2.0, 'PARP2': 1.0, 'PRKDC': 4.0, 'RAD50': 1.0, 'RAD51': 2.0, 'RAD51B': 1.0, 'RAD51C': 4.0, 'RAD51D': 1.0, 'RAD52': 4.0, 'RAD54L': 3.0, 'RBBP8': 4.0, 'RECQL': 2.0, 'RECQL4': 4.0, 'SCAI': 5.0, 'SDHB': 1.0, 'SDHC': 1.0, 'SDHD': 1.0, 'SLFN11': 4.0, 'SLX1A': 1.0, 'SLX4': 1.0, 'TP53BP1': 4.0, 'TRIP13': 5.0, 'TXNL1': 1.0, 'UBE2T': 1.0, 'UIMC1': 4.0, 'USP13': 1.0, 'WWOX': 2.0, 'XRCC1': 5.0, 'XRCC2': 1.0, 'XRCC3': 1.0, 'XRCC4': 1.0}, 
"Metabolism/Lipid": {'ACSS2': 4.0, 'CPT2': 1.0, 'CYP2E1': 5.0, 'FASN': 4.0, 'HMGCR': 2.0, 'LDLR': 4.0, 'NKX2-8': 4.0, 'SLC25A1': 5.0, 'SLC27A2': 4.0, 'TMEM97': 3.0}, 
"Metabolism/OXPHOS": {'ACSS2': 4.0, 'C1QBP': 4.0, 'COX17': 1.0, 'COX7A2': 4.0, 'CPT2': 1.0, 'FH': 5.0, 'GABPA': 1.0, 'GPX4': 2.0, 'MT-CO3': 5.0, 'NDUFA13': 4.0, 'NKX2-8': 4.0, 'PPARGC1A': 1.0, 'PPARGC1B': 1.0, 'PRKAA2': 1.0, 'SDHB': 1.0, 'SDHC': 1.0, 'SDHD': 1.0, 'SERPINB3': 4.0, 'SLC25A1': 5.0, 'TFAM': 4.0, 'TRAP1': 4.0}, 
"Transcription/Epigenetics": {'ACSS2': 4.0, 'ARID1A': 4.0, 'BRD9': 1.0, 'DNMT1': 2.0, 'EP300': 4.0, 'HDAC1': 5.0, 'KAT2B': 1.0, 'KAT5': 1.0, 'KAT8': 1.0, 'KDM5A': 1.0, 'MTRR': 5.0, 'NUP62': 1.0, 'RNF2': 5.0, 'SIX1': 4.0, 'SMARCA2': 4.0, 'SMARCA4': 4.0, 'SMARCE1': 1.0, 'TET1': 4.0, 'TET2': 1.0}, 
"ECM signaling": {'ACTN4': 3.0, 'ADAM10': 1.0, 'ADAM9': 4.0, 'AKT1S1': 1.0, 'AMFR': 3.0, 'ANXA2': 5.0, 'ANXA4': 4.0, 'ARHGDIB': 2.0, 'BMI1': 2.0, 'BRIP1': 5.0, 'CCN1': 4.0, 'CCN2': 2.0, 'CD274': 4.0, 'CD44': 5.0, 'CDC42': 1.0, 'CDK5': 1.0, 'CEBPB': 1.0, 'CLDN1': 1.0, 'CLIC1': 5.0, 'COL11A1': 4.0, 'COL1A1': 4.0, 'COL3A1': 4.0, 'COL6A3': 4.0, 'CTNNB1': 4.0, 'DDR1': 4.0, 'DIAPH3': 4.0, 'DSG1': 3.0, 'EP300': 4.0, 'EPHA2': 1.0, 'ETS1': 4.0, 'EZR': 4.0, 'FAP': 4.0, 'FAT1': 1.0, 'FN1': 4.0, 'GPRC5A': 5.0, 'IGF1': 2.0, 'IGF1R': 2.0, 'ILK': 1.0, 'ITGA5': 4.0, 'ITGA6': 4.0, 'ITGB1': 4.0, 'ITGB5': 2.0, 'ITGB6': 1.0, 'ITGB8': 2.0, 'KRT1': 1.0, 'KRT10': 1.0, 'KRT18': 4.0, 'KRT5': 4.0, 'KRT8': 2.0, 'L1CAM': 4.0, 'LRP1': 1.0, 'MAST1': 5.0, 'MMP19': 1.0, 'MMP2': 4.0, 'MMP20': 1.0, 'MMP7': 4.0, 'MMP9': 4.0, 'MSLN': 1.0, 'MUC1': 2.0, 'MUC16': 1.0, 'MUC5B': 4.0, 'NCOA3': 3.0, 'NID1': 1.0, 'PAK1': 4.0, 'PODXL': 1.0, 'POSTN': 4.0, 'PPL': 1.0, 'PROM1': 5.0, 'PRSS1': 4.0, 'PTAFR': 2.0, 'PTEN': 5.0, 'PTK2': 4.0, 'PXN': 5.0, 'RAC1': 2.0, 'RET': 2.0, 'RHOA': 5.0, 'RHOJ': 1.0, 'RORC': 2.0, 'RPSA': 1.0, 'S100B': 1.0, 'SCAI': 5.0, 'SERPINB2': 4.0, 'SLC39A4': 1.0, 'SLC3A2': 2.0, 'SLC9A3R1': 5.0, 'SOX10': 4.0, 'SPARC': 5.0, 'SPINK7': 1.0, 'SRC': 2.0, 'TGM2': 1.0, 'THBS1': 4.0, 'TIMP2': 4.0, 'TIMP3': 5.0, 'VCAM1': 2.0}, 
"EMT": {'ACTN4': 3.0, 'ADAM9': 4.0, 'BMP4': 4.0, 'CCL2': 5.0, 'CD24': 5.0, 'CDH1': 4.0, 'CDH2': 4.0, 'CIP2A': 5.0, 'CLDN1': 1.0, 'CLDN3': 2.0, 'CLDN4': 5.0, 'CLDN7': 4.0, 'CTNNA1': 4.0, 'DDB1': 1.0, 'DDB2': 5.0, 'DYRK2': 5.0, 'EIF4E': 2.0, 'EMX2': 4.0, 'EPCAM': 5.0, 'ETS1': 4.0, 'EZR': 4.0, 'FOXC2': 5.0, 'GJA1': 4.0, 'HCFC1R1': 4.0, 'HNF1B': 4.0, 'HOXA13': 4.0, 'HOXB13': 4.0, 'HOXD8': 4.0, 'IKBKB': 2.0, 'KAT2B': 1.0, 'KISS1': 4.0, 'KLF4': 4.0, 'KLF5': 4.0, 'KRT18': 4.0, 'KRT8': 2.0, 'L1CAM': 4.0, 'LRRFIP1': 1.0, 'MITF': 1.0, 'MMP7': 4.0, 'MSLN': 1.0, 'MUC16': 1.0, 'MYD88': 4.0, 'MZF1': 1.0, 'NACC1': 4.0, 'NANOG': 4.0, 'NES': 4.0, 'NID1': 1.0, 'PAK1': 4.0, 'PALLD': 2.0, 'PER2': 2.0, 'PMEL': 1.0, 'RAB18': 1.0, 'S100A4': 1.0, 'SCAI': 5.0, 'SIX1': 4.0, 'SNAI1': 1.0, 'SNAI2': 1.0, 'SOX10': 4.0, 'SRC': 2.0, 'TBX2': 4.0, 'TET1': 4.0, 'TGM2': 1.0, 'TGM3': 3.0, 'TLX3': 4.0, 'TUBB3': 3.0, 'TWIST1': 5.0, 'WWOX': 2.0, 'YAP1': 1.0, 'YWHAZ': 5.0, 'ZEB1': 2.0, 'ZEB2': 1.0}, 
"WNT signaling": {'ACTN4': 3.0, 'ANKRD1': 4.0, 'APC': 4.0, 'AURKA': 5.0, 'CLDN3': 2.0, 'CLDN4': 5.0, 'CLDN7': 4.0, 'CTNNB1': 4.0, 'DACT1': 2.0, 'DKK1': 4.0, 'DKK3': 5.0, 'DVL2': 1.0, 'DVL3': 4.0, 'EMX2': 4.0, 'EPCAM': 5.0, 'FAM83B': 4.0, 'FAT1': 1.0, 'FN1': 4.0, 'FUBP1': 4.0, 'FZD8': 2.0, 'GOLPH3': 5.0, 'GSK3B': 1.0, 'HOXA13': 4.0, 'JAG1': 5.0, 'LRRFIP1': 1.0, 'MMP10': 4.0, 'MMP7': 4.0, 'MSX1': 4.0, 'MUC5B': 4.0, 'NOTCH1': 2.0, 'NTS': 4.0, 'NTSR1': 4.0, 'PAK1': 4.0, 'PAK5': 4.0, 'PMEL': 1.0, 'POSTN': 4.0, 'PROM1': 5.0, 'PTCH1': 1.0, 'PTGER3': 2.0, 'PTGS2': 3.0, 'RBMS3': 2.0, 'ROR1': 1.0, 'ROR2': 4.0, 'SETD2': 1.0, 'SFRP5': 5.0, 'SGK1': 2.0, 'SHCBP1': 1.0, 'SLC9A3R1': 5.0, 'SOX1': 1.0, 'SOX17': 5.0, 'SOX8': 4.0, 'SOX9': 4.0, 'TBX2': 4.0, 'TIMELESS': 2.0, 'TLX3': 4.0, 'TMEM88': 2.0, 'VEGFA': 2.0, 'YAP1': 1.0, 'YTHDF1': 4.0, 'YWHAZ': 5.0}, 
"TGF-β signaling": {'ACVR1C': 4.0, 'ANKRD1': 4.0, 'BMP4': 4.0, 'BMPR1A': 4.0, 'CAV1': 1.0, 'DAXX': 5.0, 'ENG': 5.0, 'FBXO32': 5.0, 'GDF15': 4.0, 'HNF1B': 4.0, 'ID1': 4.0, 'MMP2': 4.0, 'MMP9': 4.0, 'NAP1L3': 4.0, 'NEDD4L': 1.0, 'RORC': 2.0, 'RUNX3': 4.0, 'SHCBP1': 1.0, 'SIX1': 4.0, 'SMAD4': 4.0, 'SMURF2': 1.0, 'TAB1': 1.0, 'TAB3': 1.0, 'TET2': 1.0, 'TGFB1': 5.0, 'TGFB2': 1.0, 'TGFB3': 4.0, 'TGFBI': 4.0, 'TGFBR1': 1.0, 'TGFBR2': 1.0, 'THBS1': 4.0, 'TXN': 4.0, 'WWOX': 2.0, 'YAP1': 1.0, 'YWHAZ': 5.0, 'YY1': 5.0, 'ZBTB7A': 1.0}, 
"NF-kB signaling": {'ADAM10': 1.0, 'AURKA': 5.0, 'BIRC2': 5.0, 'BIRC3': 5.0, 'BSG': 4.0, 'CARD10': 4.0, 'CD24': 5.0, 'CHUK': 5.0, 'CLU': 5.0, 'COL11A1': 4.0, 'CUEDC2': 4.0, 'CXCL12': 4.0, 'DDB2': 5.0, 'ETS1': 4.0, 'EZR': 4.0, 'FAM168A': 4.0, 'GDF15': 4.0, 'GOLPH3L': 5.0, 'HOXB13': 4.0, 'ID1': 4.0, 'IKBKB': 2.0, 'IKBKE': 4.0, 'KEAP1': 4.0, 'KISS1': 4.0, 'MALT1': 1.0, 'MAP3K7': 1.0, 'MIEN1': 1.0, 'MSLN': 1.0, 'MTDH': 4.0, 'MUC1': 2.0, 'MYD88': 4.0, 'NCOA3': 3.0, 'NFKB1': 4.0, 'NFKB2': 1.0, 'NKX2-8': 4.0, 'PINK1': 2.0, 'PRDX3': 4.0, 'PRKRA': 2.0, 'RELA': 5.0, 'RELB': 4.0, 'RIPK1': 4.0, 'ROR1': 1.0, 'S100A7': 4.0, 'SGK1': 2.0, 'SQSTM1': 1.0, 'TAB3': 1.0, 'TGM2': 1.0, 'TLR4': 2.0, 'TNF': 1.0, 'TNFAIP8': 4.0, 'TNFAIP8L2': 4.0, 'VCAM1': 2.0, 'XIAP': 4.0, 'XPO1': 5.0, 'YTHDF1': 4.0, 'ZEB2': 1.0}, 
"Notch signaling": {'ADAM10': 1.0, 'CAV1': 1.0, 'CHUK': 5.0, 'HEY1': 1.0, 'JAG1': 5.0, 'LGALS3': 1.0, 'MVP': 4.0, 'NOTCH1': 2.0, 'NOTCH3': 4.0, 'PROM1': 5.0, 'SUSD2': 5.0, 'TPT1': 4.0, 'WWP2': 5.0}, 
"Immune response": {'ADAM17': 4.0, 'ATF3': 1.0, 'BRD9': 1.0, 'CASP4': 1.0, 'CAVIN3': 4.0, 'CCL2': 5.0, 'CCL25': 4.0, 'CCL5': 5.0, 'CCR9': 4.0, 'CD274': 4.0, 'CD40': 4.0, 'CD40LG': 4.0, 'CD55': 2.0, 'CD8A': 4.0, 'CD8B': 4.0, 'CDK5': 1.0, 'CEBPB': 1.0, 'CUEDC2': 4.0, 'CXCL10': 5.0, 'CXCL12': 4.0, 'CXCL8': 4.0, 'CXCR4': 4.0, 'DOK2': 4.0, 'EGR1': 5.0, 'FOXP1': 5.0, 'FSTL1': 1.0, 'GAS6': 4.0, 'GGNBP2': 4.0, 'HDAC3': 4.0, 'HDAC4': 4.0, 'HIF1A': 4.0, 'HOXA4': 4.0, 'ICAM1': 1.0, 'ID1': 4.0, 'IFNG': 5.0, 'IL11': 4.0, 'IL17A': 1.0, 'IL1A': 2.0, 'IL1B': 1.0, 'IL22': 1.0, 'IL24': 1.0, 'IL25': 2.0, 'IL6': 5.0, 'IL6R': 5.0, 'IL7': 4.0, 'IRF1': 4.0, 'JAK2': 5.0, 'KIF20A': 4.0, 'LMO4': 1.0, 'LTB4R2': 2.0, 'MAP3K5': 1.0, 'MMP2': 4.0, 'MMP9': 4.0, 'MOAP1': 1.0, 'MUC1': 2.0, 'MUC16': 1.0, 'MVP': 4.0, 'MYD88': 4.0, 'NAV3': 4.0, 'NDUFA13': 4.0, 'PBX1': 4.0, 'PER2': 2.0, 'PIM1': 1.0, 'PLAA': 4.0, 'PTAFR': 2.0, 'PTGER3': 2.0, 'PTGS2': 3.0, 'RAB18': 1.0, 'RIPK3': 4.0, 'RORC': 2.0, 'RUNX3': 4.0, 'S100A7': 4.0, 'S100A9': 5.0, 'SCRIB': 4.0, 'SERPINB2': 4.0, 'SPP1': 1.0, 'STAT1': 4.0, 'STAT3': 4.0, 'STAT5A': 4.0, 'STAT5B': 4.0, 'STAT6': 4.0, 'STING1': 3.0, 'TAP1': 1.0, 'TET2': 1.0, 'TLR4': 2.0, 'TLX3': 4.0, 'TNF': 1.0, 'TNFAIP8': 4.0, 'TRAF2': 1.0, 'TRAP1': 4.0, 'TRIM27': 5.0, 'USP35': 4.0, 'ZBTB7A': 1.0}, 
"Detox": {'AGR3': 4.0, 'CAMK2D': 4.0, 'MT1A': 4.0, 'MT2A': 4.0, 'MT3': 1.0, 'P4HB': 1.0, 'PAPSS1': 5.0, 'PDIA3': 1.0, 'STK17A': 1.0}, 
"Apoptosis": {'AIFM1': 1.0, 'APAF1': 1.0, 'BAD': 4.0, 'BAG3': 4.0, 'BAK1': 1.0, 'BAX': 4.0, 'BBC3': 2.0, 'BCL2': 4.0, 'BCL2A1': 1.0, 'BCL2L1': 5.0, 'BCL2L11': 1.0, 'BCL2L2': 2.0, 'BHLHE40': 4.0, 'BID': 2.0, 'BIK': 1.0, 'BIRC2': 5.0, 'BIRC3': 5.0, 'BIRC5': 4.0, 'BIRC7': 1.0, 'BNIP3': 1.0, 'BOK': 4.0, 'CAPNS1': 1.0, 'CASP14': 4.0, 'CASP2': 1.0, 'CASP3': 1.0, 'CASP6': 2.0, 'CASP7': 1.0, 'CASP8': 1.0, 'CASP9': 1.0, 'CFLAR': 1.0, 'DAPK1': 1.0, 'DAPK3': 4.0, 'DDIAS': 1.0, 'DIRAS3': 5.0, 'HK2': 2.0, 'MCL1': 4.0, 'VDAC1': 1.0, 'XAF1': 4.0, 'XIAP': 4.0}, 
"Redox": {'AKR1B10': 1.0, 'AKR1C1': 4.0, 'AKR1C2': 5.0, 'AKR1C3': 4.0, 'ALDH1A1': 5.0, 'ALDH3A1': 2.0, 'APEX1': 1.0, 'ASS1': 4.0, 'ATM': 3.0, 'CD40': 4.0, 'CLIC1': 5.0, 'CUL3': 4.0, 'CYP1B1': 4.0, 'CYP2E1': 5.0, 'DUOXA1': 5.0, 'EIF2AK3': 2.0, 'FOXO1': 4.0, 'FOXO3': 5.0, 'G6PD': 4.0, 'GPX3': 2.0, 'GPX4': 2.0, 'H6PD': 1.0, 'HMGB1': 4.0, 'HMGB2': 1.0, 'HMOX1': 4.0, 'HOXA4': 4.0, 'HOXB3': 4.0, 'KEAP1': 4.0, 'MAFG': 4.0, 'MBNL1': 1.0, 'NES': 4.0, 'NFE2L2': 4.0, 'NOS1': 1.0, 'NOS2': 1.0, 'NOS3': 1.0, 'NOX5': 2.0, 'NQO1': 4.0, 'PAQR3': 1.0, 'PARK7': 4.0, 'PGD': 4.0, 'PHGDH': 2.0, 'PKM': 4.0, 'PRDX3': 4.0, 'SAT1': 1.0, 'SCRIB': 4.0, 'SERPINB3': 4.0, 'SESN1': 1.0, 'SHC1': 1.0, 'SIRT1': 5.0, 'SIRT3': 1.0, 'SLC25A1': 5.0, 'SLC40A1': 1.0, 'SMARCA4': 4.0, 'SOD1': 1.0, 'SOD2': 4.0, 'SOX9': 4.0, 'SQSTM1': 1.0, 'STK17A': 1.0, 'TIGAR': 1.0, 'TRIM27': 5.0, 'TXN': 4.0, 'TXNDC17': 1.0, 'TXNRD1': 1.0, 'TXNRD2': 1.0, 'TXNRD3': 1.0, 'UCP2': 4.0, 'UNG': 1.0, 'YTHDF1': 4.0}, 
"Oncogene signaling/AKT": {'AKR1C2': 5.0, 'BAD': 4.0, 'BCL2L11': 1.0, 'BIRC5': 4.0, 'CASP3': 1.0, 'CAVIN3': 4.0, 'CD24': 5.0, 'COL11A1': 4.0, 'CSF1': 5.0, 'CSF1R': 5.0, 'ENO1': 2.0, 'EZR': 4.0, 'FAM168A': 4.0, 'GSK3B': 1.0, 'HCFC1R1': 4.0, 'HOXB4': 1.0, 'LMO4': 1.0, 'MEN1': 1.0, 'MIEN1': 1.0, 'MTDH': 4.0, 'NOX5': 2.0, 'NR1I2': 1.0, 'PARK7': 4.0, 'PBXIP1': 4.0, 'PDPK1': 1.0, 'PEA15': 2.0, 'PER2': 2.0, 'PIK3CA': 5.0, 'PIK3CB': 1.0, 'PIK3CG': 1.0, 'PIK3R1': 2.0, 'PIK3R2': 2.0, 'PPL': 1.0, 'PTEN': 5.0, 'RGS10': 1.0, 'RGS17': 1.0, 'RPL36': 1.0, 'SIK2': 1.0, 'SPP1': 1.0, 'SRC': 2.0, 'TGFBI': 4.0, 'TMEM98': 5.0, 'TP53BP1': 4.0, 'TUBB3': 3.0, 'TWIST1': 5.0, 'YWHAG': 1.0, 'YWHAH': 1.0, 'YWHAQ': 1.0, 'YWHAZ': 5.0, 'YY1': 5.0}, 
"AMPK signaling": {'AKT1': 5.0, 'AKT2': 2.0, 'FOXO1': 4.0, 'FOXO3': 5.0, 'GFRA1': 4.0, 'HMGCR': 2.0, 'IGF1': 2.0, 'IGF1R': 2.0, 'PFKFB3': 2.0, 'PPP2CA': 4.0, 'PPP2R2A': 1.0, 'PRKAA1': 4.0, 'PRKAA2': 1.0, 'PRKAB1': 1.0, 'PRKAB2': 1.0, 'STK11': 2.0}, 
"mTOR signaling": {'AKT1': 5.0, 'AKT1S1': 1.0, 'AKT2': 2.0, 'EIF4EBP1': 2.0, 'LAMTOR5': 5.0, 'MTOR': 2.0, 'RPS4X': 4.0, 'RPS6KA1': 1.0, 'RPS6KA3': 1.0, 'RPS6KB1': 1.0, 'RPS6KB2': 1.0, 'SESN1': 1.0, 'SLC3A2': 2.0, 'SLC7A5': 1.0, 'TPT1': 4.0, 'TXNDC17': 1.0, 'URI1': 5.0}, 
"Metabolism/Glycolysis": {'ALDOA': 1.0, 'ENO1': 2.0, 'HK2': 2.0, 'ITGA5': 4.0, 'ITGB5': 2.0, 'MICU1': 5.0, 'PDK1': 5.0, 'PDK4': 2.0, 'PFKFB3': 2.0, 'PGK1': 4.0, 'PIK3R1': 2.0, 'PKM': 4.0, 'PRKAA2': 1.0, 'SLC2A1': 4.0, 'SLC2A14': 4.0, 'TIGAR': 1.0}, 
"Cell cycle": {'ALKBH3': 2.0, 'ANKRD1': 4.0, 'ATM': 3.0, 'ATR': 1.0, 'BIN1': 1.0, 'BMI1': 2.0, 'CCDC69': 4.0, 'CCNA2': 5.0, 'CCND1': 4.0, 'CCNE1': 4.0, 'CCNI': 4.0, 'CDC7': 1.0, 'CDK2': 4.0, 'CDK4': 5.0, 'CDK5': 1.0, 'CDK7': 4.0, 'CDKN1A': 4.0, 'CDKN1B': 4.0, 'CDKN1C': 4.0, 'CDKN2A': 1.0, 'CDKN2C': 1.0, 'CHEK1': 5.0, 'CHEK2': 4.0, 'CUEDC2': 4.0, 'E2F1': 4.0, 'E2F2': 4.0, 'E2F4': 4.0, 'E2F7': 4.0, 'EGF': 1.0, 'FOS': 4.0, 'FOSB': 4.0, 'FOXO1': 4.0, 'GABPA': 1.0, 'GADD45A': 4.0, 'GADD45GIP1': 4.0, 'GGNBP2': 4.0, 'HOXB3': 4.0, 'ID1': 4.0, 'JUN': 5.0, 'JUNB': 2.0, 'JUND': 1.0, 'KAT2B': 1.0, 'KDM3A': 2.0, 'KRT10': 1.0, 'LIN28A': 4.0, 'MELK': 4.0, 'MITF': 1.0, 'MYC': 5.0, 'NCOA3': 3.0, 'NEDD8': 5.0, 'NEK11': 1.0, 'NES': 4.0, 'NUP62': 1.0, 'PHB': 4.0, 'PLK1': 5.0, 'PLK2': 4.0, 'PRKDC': 4.0, 'RB1': 4.0, 'RNF2': 5.0, 'RPS20': 4.0, 'SMARCE1': 1.0, 'SOX10': 4.0, 'TBX2': 4.0, 'TP53': 5.0, 'TRIB2': 4.0, 'TRIP13': 5.0, 'UBE2S': 4.0, 'XPO1': 5.0}, 
"Autophagy": {'AMBRA1': 4.0, 'ARIH1': 4.0, 'ATF6': 4.0, 'ATG12': 1.0, 'ATG14': 2.0, 'ATG5': 1.0, 'ATG7': 1.0, 'BAG3': 4.0, 'BECN1': 4.0, 'BNIP3': 1.0, 'BSG': 4.0, 'CLDN1': 1.0, 'CLU': 5.0, 'DACT1': 2.0, 'DIRAS3': 5.0, 'DRAM1': 1.0, 'EGR1': 5.0, 'EZH2': 4.0, 'GFRA1': 4.0, 'HK2': 2.0, 'HMGB1': 4.0, 'HMGB2': 1.0, 'HSPB1': 4.0, 'ID1': 4.0, 'ITM2A': 5.0, 'MAP1LC3A': 4.0, 'MAP1LC3B': 1.0, 'MTOR': 2.0, 'MTRR': 5.0, 'NACC1': 4.0, 'PGRMC1': 2.0, 'PINK1': 2.0, 'PLK1': 5.0, 'PPP2CA': 4.0, 'PRKAA1': 4.0, 'PRKAB1': 1.0, 'PRKAB2': 1.0, 'PRR13': 5.0, 'SOX1': 1.0, 'SQSTM1': 1.0, 'SUSD2': 5.0, 'TNFAIP8': 4.0, 'TNFAIP8L2': 4.0, 'TP53INP1': 1.0, 'TRIM65': 4.0, 'TXNDC17': 1.0, 'UVRAG': 1.0}, 
"Oncogene signaling/MAPK": {'ANXA2': 5.0, 'ATF2': 1.0, 'CSF1': 5.0, 'CSF1R': 5.0, 'CXCR4': 4.0, 'DUSP1': 1.0, 'DUSP6': 2.0, 'FOS': 4.0, 'FOSB': 4.0, 'FOSL1': 4.0, 'FOXP1': 5.0, 'JUN': 5.0, 'JUNB': 2.0, 'JUND': 1.0, 'KRAS': 4.0, 'KRT10': 1.0, 'MAP2K1': 4.0, 'MAP2K3': 1.0, 'MAP2K4': 1.0, 'MAP3K1': 1.0, 'MAP3K5': 1.0, 'MAP3K7': 1.0, 'MAPK1': 1.0, 'MAPK11': 1.0, 'MAPK12': 1.0, 'MAPK13': 1.0, 'MAPK14': 1.0, 'MAPK3': 1.0, 'MAPK8': 1.0, 'MAPK9': 1.0, 'MAST1': 5.0, 'NRAS': 1.0, 'NTSR1': 4.0, 'PAK5': 4.0, 'PAQR3': 1.0, 'PEA15': 2.0, 'PRKG1': 4.0, 'PRSS1': 4.0, 'RBM17': 1.0, 'RNF2': 5.0, 'RPS6KA1': 1.0, 'RPS6KA3': 1.0, 'RPS6KB1': 1.0, 'RPS6KB2': 1.0}, 
"DDR/BER": {'APEX1': 1.0, 'FEN1': 4.0, 'HMGB1': 4.0, 'HMGB2': 1.0, 'LIG3': 1.0, 'MUTYH': 1.0, 'NEIL2': 3.0, 'NPM1': 3.0, 'NTHL1': 1.0, 'PNKP': 1.0, 'POLB': 4.0, 'UNG': 1.0, 'XRCC1': 5.0}, 
"Platinum uptake": {'AQP2': 1.0, 'ATP1A1': 1.0, 'ATP1B1': 1.0, 'CLDN3': 2.0, 'CLDN4': 5.0, 'EZH2': 4.0, 'FOLR1': 4.0, 'IGF2BP3': 4.0, 'IL1A': 2.0, 'LIN28B': 4.0, 'LRRC8A': 4.0, 'LRRC8D': 4.0, 'RAB5C': 1.0, 'SLC22A1': 1.0, 'SLC22A2': 4.0, 'SLC22A3': 1.0, 'SLC31A1': 4.0, 'SLC31A2': 4.0, 'SLC40A1': 1.0, 'SLCO1B3': 1.0, 'TFRC': 1.0}, 
"Oncogene signaling/EGFR": {'AREG': 5.0, 'CUZD1': 4.0, 'DERL1': 4.0, 'DNMT1': 2.0, 'DOK2': 4.0, 'EGF': 1.0, 'EGFR': 4.0, 'ERBB2': 5.0, 'ERBB3': 4.0, 'ERBB4': 4.0, 'FAM83B': 4.0, 'HBEGF': 2.0, 'HECTD3': 5.0, 'IGF1': 2.0, 'IGF1R': 2.0, 'IGF2': 1.0, 'IGF2R': 4.0, 'KDM5A': 1.0, 'KRAS': 4.0, 'LNPEP': 4.0, 'PDK1': 5.0, 'PTPN3': 2.0}, 
"Translation": {'ARIH1': 4.0, 'EIF2S1': 1.0, 'EIF3A': 4.0, 'EIF3G': 1.0, 'EIF4E': 2.0, 'EIF4EBP1': 2.0, 'EIF4G2': 4.0, 'KDM5A': 1.0, 'METTL6': 4.0, 'MTOR': 2.0, 'PDCD4': 2.0, 'RPL36': 1.0, 'RPS20': 4.0, 'RPS4X': 4.0, 'RPS6KA1': 1.0, 'RPS6KA3': 1.0, 'RPS6KB1': 1.0, 'RPS6KB2': 1.0, 'RPS7': 1.0, 'YTHDF1': 4.0}, 
"Redox/Glutathione": {'ARL6IP5': 4.0, 'ATF4': 2.0, 'BAP1': 4.0, 'BCAT1': 4.0, 'CBS': 4.0, 'CD8A': 4.0, 'CD8B': 4.0, 'CLOCK': 4.0, 'FAT1': 1.0, 'GCLC': 4.0, 'GCLM': 2.0, 'GGT1': 1.0, 'GLS': 4.0, 'GLS2': 4.0, 'GPX3': 2.0, 'GSR': 4.0, 'GSS': 4.0, 'GSTA1': 1.0, 'GSTK1': 1.0, 'GSTM1': 1.0, 'GSTM4': 4.0, 'GSTP1': 4.0, 'GSTT1': 4.0, 'KISS1': 4.0, 'PDXK': 4.0, 'PDXP': 1.0, 'PHGDH': 2.0, 'RRM2': 1.0, 'SLC1A5': 1.0, 'SLC3A2': 2.0, 'SLC7A11': 4.0, 'ZEB1': 2.0}, 
"Hypoxia signaling": {'ARNT': 2.0, 'C1QBP': 4.0, 'CAMK2D': 4.0, 'EGF': 1.0, 'EGLN1': 4.0, 'EGLN3': 1.0, 'EIF4E': 2.0, 'FH': 5.0, 'GPRC5A': 5.0, 'HIF1A': 4.0, 'HMOX1': 4.0, 'HOXD8': 4.0, 'PDK1': 5.0, 'PFKFB3': 2.0, 'RAB25': 2.0, 'RAB7A': 4.0, 'RELA': 5.0, 'RELB': 4.0, 'RHOA': 5.0, 'SENP1': 1.0, 'SERPINE1': 1.0, 'STAT3': 4.0, 'TET1': 4.0, 'TFRC': 1.0, 'TGM2': 1.0, 'TXN': 4.0, 'UBE2S': 4.0, 'VEGFA': 2.0, 'VHL': 1.0}, 
"DDR/nucleotide synthesis": {'ASS1': 4.0, 'CAD': 3.0, 'CLIC1': 5.0, 'DHFR': 1.0, 'FOLR2': 1.0, 'G6PD': 4.0, 'H6PD': 1.0, 'NT5E': 4.0, 'PGD': 4.0, 'RRM2': 1.0, 'SLC19A1': 1.0, 'SLC46A1': 1.0, 'TIGAR': 1.0}, 
"Metabolism/amino acid": {'ASS1': 4.0, 'DHFR': 1.0, 'FOLR2': 1.0, 'PHGDH': 2.0, 'SLC46A1': 1.0, 'SLC7A5': 1.0}, 
"ER stress response": {'ATF3': 1.0, 'ATF6': 4.0, 'CASP12': 2.0, 'CASP4': 1.0, 'CYP2E1': 5.0, 'DDIT3': 5.0, 'DERL1': 4.0, 'EIF2AK3': 2.0, 'EIF2S1': 1.0, 'ERN1': 1.0, 'HSPA5': 5.0, 'NACC1': 4.0, 'PDIA3': 1.0, 'THBS1': 4.0, 'TRAF2': 1.0, 'XBP1': 1.0}, 
"Transcription": {'ATF3': 1.0, 'ATF4': 2.0, 'CEBPB': 1.0, 'DAXX': 5.0, 'E2F4': 4.0, 'EZH2': 4.0, 'FOS': 4.0, 'FOSB': 4.0, 'FOXP1': 5.0, 'GABPA': 1.0, 'GGNBP2': 4.0, 'GPBP1': 4.0, 'HDAC3': 4.0, 'HDAC4': 4.0, 'HNF1B': 4.0, 'HOXA13': 4.0, 'HOXA4': 4.0, 'HOXB13': 4.0, 'HOXB3': 4.0, 'HOXB4': 1.0, 'HOXB7': 4.0, 'HOXD8': 4.0, 'HSF1': 4.0, 'JUN': 5.0, 'JUNB': 2.0, 'JUND': 1.0, 'KLF4': 4.0, 'KLF5': 4.0, 'LRRFIP1': 1.0, 'MITF': 1.0, 'MZF1': 1.0, 'NACC1': 4.0, 'NANOG': 4.0, 'NAP1L3': 4.0, 'NFE2L2': 4.0, 'NR1I2': 1.0, 'RORC': 2.0, 'SETD2': 1.0, 'SLC27A2': 4.0, 'SOX1': 1.0, 'SREBF2': 1.0, 'STAT1': 4.0, 'STAT3': 4.0, 'TLX3': 4.0, 'USP22': 2.0, 'ZEB1': 2.0, 'ZNF143': 1.0, 'ZNF93': 1.0}, 
"pH/Pt-toxicity": {'ATP6V0B': 1.0, 'ATP6V0D1': 1.0, 'ATP6V1A': 1.0, 'ATP6V1B2': 1.0, 'ATP6V1C1': 1.0, 'ATP6V1E1': 1.0}, 
"ubiquitin": {'BIRC2': 5.0, 'BIRC3': 5.0, 'CUEDC2': 4.0, 'CUL3': 4.0, 'DAXX': 5.0, 'NEDD4': 1.0, 'NEDD4L': 1.0, 'NEDD8': 5.0, 'TRIM27': 5.0, 'TXNL1': 1.0, 'UBE2S': 4.0, 'UBR5': 4.0, 'UCHL1': 1.0, 'USP1': 1.0, 'USP35': 4.0, 'WDR48': 1.0}, 
"DDR/DNA replication": {'BRCA1': 5.0, 'BRCA2': 5.0, 'CHD4': 4.0, 'KMT2B': 1.0, 'PAXIP1': 2.0, 'RAD52': 4.0, 'RADX': 1.0, 'RNF2': 5.0, 'SLFN11': 4.0}, 
"DDR/FA": {'BRCA2': 5.0, 'DCLRE1B': 1.0, 'FAAP100': 1.0, 'FAAP24': 1.0, 'FAN1': 1.0, 'FANCA': 4.0, 'FANCB': 1.0, 'FANCC': 1.0, 'FANCD2': 4.0, 'FANCE': 1.0, 'FANCF': 1.0, 'FANCG': 1.0, 'FANCI': 4.0, 'FANCL': 1.0, 'FANCM': 3.0, 'FEN1': 4.0, 'RAD51': 2.0, 'RAD51C': 4.0, 'UBE2T': 1.0, 'USP1': 1.0}, 
"MYC signaling": {'BRD9': 1.0, 'CIP2A': 5.0, 'FUBP1': 4.0, 'HDAC1': 5.0, 'MYC': 5.0, 'PIM1': 1.0, 'PIM2': 1.0, 'USP22': 2.0, 'ZNF93': 1.0}, 
"DDR/NER": {'CAD': 3.0, 'CDK7': 4.0, 'CUL4A': 5.0, 'DDB1': 1.0, 'DDB2': 5.0, 'EGR1': 5.0, 'EIF3A': 4.0, 'ERCC1': 4.0, 'ERCC2': 4.0, 'ERCC3': 4.0, 'ERCC4': 4.0, 'ERCC5': 4.0, 'ERCC6': 4.0, 'ERCC8': 4.0, 'FEN1': 4.0, 'GADD45A': 4.0, 'HBEGF': 2.0, 'HMGB1': 4.0, 'HMGB2': 1.0, 'LIG3': 1.0, 'MNAT1': 1.0, 'MZF1': 1.0, 'RAD23B': 1.0, 'RIF1': 4.0, 'TIE1': 4.0, 'UVRAG': 1.0, 'XPA': 4.0, 'XPC': 1.0}, 
"Chaperone": {'CDC37': 1.0, 'CRYAB': 4.0, 'HSF1': 4.0, 'HSP90AA1': 5.0, 'HSP90AB1': 4.0, 'HSPA1A': 5.0, 'HSPA1B': 2.0, 'HSPA5': 5.0, 'HSPB1': 4.0, 'HSPD1': 1.0, 'PDIA3': 1.0, 'PGK1': 4.0, 'PHB': 4.0, 'TBCE': 1.0, 'TRAP1': 4.0}, 
"Hippo signaling": {'CDH1': 4.0, 'FGF1': 4.0, 'FGFR2': 1.0, 'GPRC5A': 5.0, 'LATS1': 1.0, 'NF2': 3.0, 'PRKG1': 4.0, 'RASSF1': 4.0, 'RHOA': 5.0, 'S100A7': 4.0, 'SAV1': 2.0, 'STK4': 1.0, 'TEAD1': 1.0, 'TEAD3': 1.0, 'TEAD4': 1.0, 'WWTR1': 1.0, 'YAP1': 1.0}, 
"DDR/NHEJ": {'DCLRE1C': 1.0, 'HOXB7': 4.0, 'LIG4': 1.0, 'MLH1': 4.0, 'PNKP': 1.0, 'PRKDC': 4.0, 'SCAI': 5.0, 'TP53BP1': 4.0, 'TRIP13': 5.0, 'XRCC5': 4.0, 'XRCC6': 4.0, 'ZBTB7A': 1.0}, 
"Metabolism/One carbon": {'DHFR': 1.0, 'FOLR2': 1.0, 'MTRR': 5.0, 'SLC19A1': 1.0, 'SLC46A1': 1.0}, 
"RNA processing": {'DICER1': 4.0, 'IGF2BP1': 1.0, 'IGF2BP3': 4.0, 'LIN28A': 4.0, 'LIN28B': 4.0, 'LUC7L3': 1.0, 'MBNL1': 1.0, 'PIWIL2': 1.0, 'PRKRA': 2.0, 'RBM17': 1.0, 'RBM3': 4.0, 'RBMS3': 2.0, 'RNASET2': 4.0, 'SRPK1': 1.0, 'SRPK2': 1.0, 'SRSF2': 4.0, 'SRSF4': 1.0, 'YBX1': 1.0}, 
"Apoptotic signaling/MAPK": {'EIF2S1': 1.0, 'GADD45A': 4.0, 'MAP2K1': 4.0, 'MAP2K3': 1.0, 'MAP2K4': 1.0, 'MAP3K1': 1.0, 'MAP3K5': 1.0, 'MAPK1': 1.0, 'MAPK11': 1.0, 'MAPK12': 1.0, 'MAPK13': 1.0, 'MAPK14': 1.0, 'MAPK3': 1.0, 'MAPK8': 1.0, 'MAPK9': 1.0, 'NR4A1': 5.0, 'TAB1': 1.0}, 
"Apoptotic signaling/DR": {'FADD': 4.0, 'FAS': 4.0, 'FASLG': 4.0, 'RBM17': 1.0, 'RIPK1': 4.0, 'ST6GAL1': 1.0, 'TNFRSF10A': 1.0, 'TNFRSF10B': 5.0, 'TNFSF10': 4.0, 'TRO': 4.0}, 
"DDR/TLS": {'FANCG': 1.0, 'MAD2L2': 2.0, 'PCLAF': 4.0, 'PCNA': 5.0, 'POLB': 4.0, 'POLH': 1.0, 'POLK': 1.0, 'REV1': 4.0, 'REV3L': 4.0}, 
"Oncogene signaling/VEGF": {'FLT4': 5.0, 'KDR': 1.0, 'NOS1': 1.0, 'NOS3': 1.0, 'NRP2': 1.0, 'VEGFA': 2.0}, 
"Metabolism/PPP": {'G6PD': 4.0, 'H6PD': 1.0, 'PGD': 4.0, 'PKM': 4.0, 'TIGAR': 1.0}, 
"Glycosylation": {'GNPTG': 1.0, 'MGAT1': 1.0, 'MGAT5': 1.0, 'ST6GAL1': 1.0, 'UGCG': 2.0}, 
"Apoptotic signaling/MMR": {'MLH1': 4.0, 'MSH2': 4.0, 'MSH6': 4.0, 'PMS2': 4.0, 'SETD2': 1.0}, 
"DDR/MMR": {'MLH1': 4.0, 'MSH2': 4.0, 'MSH3': 1.0, 'MSH5': 1.0, 'MSH6': 4.0, 'PMS2': 4.0}, 
}