export const traceData1 = {
x: ['Apoptotic signaling', 'ECM signaling', 'Immune response', 'CSC', 'EMT', 'DDR/HR', 'Redox', 'Cell cycle', 'Oncogene signaling', 'DDR', 'WNT signaling', 'NF-kB signaling', 'p53 signaling', 'Platinum efflux', 'Oncogene signaling/AKT', 'Transcription', 'Autophagy', 'Oncogene signaling/MAPK', 'Apoptosis', 'TGF-β signaling', 'Redox/Glutathione', 'Hypoxia signaling', 'DDR/NER', 'Oncogene signaling/EGFR', 'Metabolism/OXPHOS', 'Platinum uptake', 'Translation', 'DDR/FA', 'Transcription/Epigenetics', 'PKA signaling', 'RNA processing', 'mTOR signaling', 'Hippo signaling', 'Apoptotic signaling/MAPK', 'AMPK signaling', 'Metabolism/Glycolysis', 'ER stress response', 'ubiquitin', 'Chaperone', 'Notch signaling', 'DDR/BER', 'DDR/nucleotide synthesis', 'DDR/NHEJ', 'Metabolism/Lipid', 'Apoptotic signaling/DR', 'Calcium signaling', 'Detox', 'DDR/DNA replication', 'MYC signaling', 'DDR/TLS', 'Hedgehog signaling', 'Metabolism/amino acid', 'pH/Pt-toxicity', 'Oncogene signaling/VEGF', 'DDR/MMR', 'Metabolism/One carbon', 'Metabolism/PPP', 'Glycosylation', 'Apoptotic signaling/MMR'],
y: [36, 26, 23, 19, 17, 28, 23, 15, 12, 30, 11, 12, 23, 20, 20, 14, 15, 24, 18, 12, 8, 7, 8, 3, 9, 9, 9, 12, 8, 8, 9, 8, 8, 14, 4, 3, 7, 6, 4, 4, 7, 7, 4, 1, 3, 3, 4, 2, 4, 3, 1, 4, 6, 4, 2, 4, 2, 4, 1],
}
export const traceData2 = {
x: ['Apoptotic signaling', 'ECM signaling', 'Immune response', 'CSC', 'EMT', 'DDR/HR', 'Redox', 'Cell cycle', 'Oncogene signaling', 'DDR', 'WNT signaling', 'NF-kB signaling', 'p53 signaling', 'Platinum efflux', 'Oncogene signaling/AKT', 'Transcription', 'Autophagy', 'Oncogene signaling/MAPK', 'Apoptosis', 'TGF-β signaling', 'Redox/Glutathione', 'Hypoxia signaling', 'DDR/NER', 'Oncogene signaling/EGFR', 'Metabolism/OXPHOS', 'Platinum uptake', 'Translation', 'DDR/FA', 'Transcription/Epigenetics', 'PKA signaling', 'RNA processing', 'mTOR signaling', 'Hippo signaling', 'Apoptotic signaling/MAPK', 'AMPK signaling', 'Metabolism/Glycolysis', 'ER stress response', 'ubiquitin', 'Chaperone', 'Notch signaling', 'DDR/BER', 'DDR/nucleotide synthesis', 'DDR/NHEJ', 'Metabolism/Lipid', 'Apoptotic signaling/DR', 'Calcium signaling', 'Detox', 'DDR/DNA replication', 'MYC signaling', 'DDR/TLS', 'Hedgehog signaling', 'Metabolism/amino acid', 'pH/Pt-toxicity', 'Oncogene signaling/VEGF', 'DDR/MMR', 'Metabolism/One carbon', 'Metabolism/PPP', 'Glycosylation', 'Apoptotic signaling/MMR'],
y: [10, 16, 10, 12, 9, 6, 6, 4, 10, 5, 10, 7, 5, 6, 7, 5, 6, 3, 5, 2, 6, 5, 1, 5, 1, 2, 4, 1, 1, 3, 2, 4, 1, 0, 6, 6, 2, 0, 1, 1, 0, 0, 0, 1, 0, 1, 0, 1, 1, 1, 1, 1, 0, 1, 0, 0, 0, 1, 0],
}
export const traceData3 = {
x: ['Apoptotic signaling', 'ECM signaling', 'Immune response', 'CSC', 'EMT', 'DDR/HR', 'Redox', 'Cell cycle', 'Oncogene signaling', 'DDR', 'WNT signaling', 'NF-kB signaling', 'p53 signaling', 'Platinum efflux', 'Oncogene signaling/AKT', 'Transcription', 'Autophagy', 'Oncogene signaling/MAPK', 'Apoptosis', 'TGF-β signaling', 'Redox/Glutathione', 'Hypoxia signaling', 'DDR/NER', 'Oncogene signaling/EGFR', 'Metabolism/OXPHOS', 'Platinum uptake', 'Translation', 'DDR/FA', 'Transcription/Epigenetics', 'PKA signaling', 'RNA processing', 'mTOR signaling', 'Hippo signaling', 'Apoptotic signaling/MAPK', 'AMPK signaling', 'Metabolism/Glycolysis', 'ER stress response', 'ubiquitin', 'Chaperone', 'Notch signaling', 'DDR/BER', 'DDR/nucleotide synthesis', 'DDR/NHEJ', 'Metabolism/Lipid', 'Apoptotic signaling/DR', 'Calcium signaling', 'Detox', 'DDR/DNA replication', 'MYC signaling', 'DDR/TLS', 'Hedgehog signaling', 'Metabolism/amino acid', 'pH/Pt-toxicity', 'Oncogene signaling/VEGF', 'DDR/MMR', 'Metabolism/One carbon', 'Metabolism/PPP', 'Glycosylation', 'Apoptotic signaling/MMR'],
y: [2, 4, 2, 0, 3, 1, 1, 2, 6, 4, 2, 1, 2, 1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 2, 1, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
}
export const traceData4 = {
x: ['Apoptotic signaling', 'ECM signaling', 'Immune response', 'CSC', 'EMT', 'DDR/HR', 'Redox', 'Cell cycle', 'Oncogene signaling', 'DDR', 'WNT signaling', 'NF-kB signaling', 'p53 signaling', 'Platinum efflux', 'Oncogene signaling/AKT', 'Transcription', 'Autophagy', 'Oncogene signaling/MAPK', 'Apoptosis', 'TGF-β signaling', 'Redox/Glutathione', 'Hypoxia signaling', 'DDR/NER', 'Oncogene signaling/EGFR', 'Metabolism/OXPHOS', 'Platinum uptake', 'Translation', 'DDR/FA', 'Transcription/Epigenetics', 'PKA signaling', 'RNA processing', 'mTOR signaling', 'Hippo signaling', 'Apoptotic signaling/MAPK', 'AMPK signaling', 'Metabolism/Glycolysis', 'ER stress response', 'ubiquitin', 'Chaperone', 'Notch signaling', 'DDR/BER', 'DDR/nucleotide synthesis', 'DDR/NHEJ', 'Metabolism/Lipid', 'Apoptotic signaling/DR', 'Calcium signaling', 'Detox', 'DDR/DNA replication', 'MYC signaling', 'DDR/TLS', 'Hedgehog signaling', 'Metabolism/amino acid', 'pH/Pt-toxicity', 'Oncogene signaling/VEGF', 'DDR/MMR', 'Metabolism/One carbon', 'Metabolism/PPP', 'Glycosylation', 'Apoptotic signaling/MMR'],
y: [43, 34, 47, 37, 31, 26, 29, 36, 30, 19, 26, 26, 12, 20, 12, 26, 18, 10, 12, 17, 18, 12, 15, 10, 8, 9, 7, 5, 7, 7, 7, 2, 5, 2, 4, 5, 4, 5, 7, 3, 3, 4, 6, 5, 6, 3, 4, 3, 1, 4, 4, 1, 0, 0, 4, 0, 3, 0, 4],
}
export const traceData5 = {
x: ['Apoptotic signaling', 'ECM signaling', 'Immune response', 'CSC', 'EMT', 'DDR/HR', 'Redox', 'Cell cycle', 'Oncogene signaling', 'DDR', 'WNT signaling', 'NF-kB signaling', 'p53 signaling', 'Platinum efflux', 'Oncogene signaling/AKT', 'Transcription', 'Autophagy', 'Oncogene signaling/MAPK', 'Apoptosis', 'TGF-β signaling', 'Redox/Glutathione', 'Hypoxia signaling', 'DDR/NER', 'Oncogene signaling/EGFR', 'Metabolism/OXPHOS', 'Platinum uptake', 'Translation', 'DDR/FA', 'Transcription/Epigenetics', 'PKA signaling', 'RNA processing', 'mTOR signaling', 'Hippo signaling', 'Apoptotic signaling/MAPK', 'AMPK signaling', 'Metabolism/Glycolysis', 'ER stress response', 'ubiquitin', 'Chaperone', 'Notch signaling', 'DDR/BER', 'DDR/nucleotide synthesis', 'DDR/NHEJ', 'Metabolism/Lipid', 'Apoptotic signaling/DR', 'Calcium signaling', 'Detox', 'DDR/DNA replication', 'MYC signaling', 'DDR/TLS', 'Hedgehog signaling', 'Metabolism/amino acid', 'pH/Pt-toxicity', 'Oncogene signaling/VEGF', 'DDR/MMR', 'Metabolism/One carbon', 'Metabolism/PPP', 'Glycosylation', 'Apoptotic signaling/MMR'],
y: [15, 14, 11, 15, 11, 8, 9, 11, 9, 7, 11, 10, 9, 3, 10, 3, 8, 7, 4, 6, 0, 5, 3, 4, 3, 1, 0, 1, 3, 0, 0, 3, 2, 1, 2, 2, 3, 5, 3, 5, 1, 1, 2, 2, 1, 1, 1, 3, 3, 1, 1, 0, 0, 1, 0, 1, 0, 0, 0],
}
